body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flatpickr-monthSelect-month.selected,
.flatpickr-monthSelect-month.startRange,
.flatpickr-monthSelect-month.endRange {
  background-color: var(--vz-indigo) !important;
}
#react-select-4-listbox {
  z-index: 100;
}

.timeline-c {
  border-left: 3px dashed #d8d8d8;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  margin-left: auto;
  letter-spacing: 0.2px;
  position: relative;
  line-height: 1.4em;
  font-size: 1.03em;
  padding: 50px;
  list-style: none;
  text-align: left;
  max-width: 70%;
}

@media (max-width: 767px) {
  .timeline-c {
    max-width: 98%;
    padding: 25px;
    margin-left: 'none';
  }
}

.timeline-c h1 {
  font-weight: 300;
  font-size: 1.4em;
}

.timeline-c h2,
.timeline-c h3 {
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 10px;
}

.timeline-c .event {
  border-bottom: 1px dashed #e8ebf1;
  padding-bottom: 25px;
  margin-bottom: 25px;
  position: relative;
}

@media (max-width: 767px) {
  .timeline-c .event {
    padding-top: 30px;
  }
}

.timeline-c .event:last-of-type {
  padding-bottom: 0;
  margin-bottom: 0;
  border: none;
}

.timeline-c .event:before,
.timeline-c .event:after {
  position: absolute;
  display: block;
  top: 0;
}

.timeline-c .event:before {
  left: -207px;
  content: attr(data-date);
  text-align: right;
  font-weight: 100;
  font-size: 0.9em;
  min-width: 120px;
}

@media (max-width: 767px) {
  .timeline-c .event:before {
    left: 0px;
    text-align: left;
  }
}

.timeline-c .event:after {
  -webkit-box-shadow: 0 0 0 3px #d4d8dd;
  box-shadow: 0 0 0 3px #d4d8dd;
  background: #b5bdc5;
  left: -58.8px;
  border-radius: 50%;
  height: 15px;
  width: 15px;
  content: '';
  top: 5px;
}

.timeline-c .event.active:after {
  background: var(--vz-indigo);
}

@media (max-width: 767px) {
  .timeline-c .event:after {
    left: -31.8px;
  }
}

.rtl .timeline-c {
  border-left: 0;
  text-align: right;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  border-right: 3px solid #727cf5;
}

.rtl .timeline-c .event::before {
  left: 0;
  right: -170px;
}

.rtl .timeline-c .event::after {
  left: 0;
  right: -55.8px;
}

.btn {
  border: none;
  border-radius: 100px;
}

.btn-primary {
  background: linear-gradient(110.33deg, #142c43 22.13%, #1c4967 90.12%);
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 20px;
}

.btn-primary-outline {
  background: #fff;
  border: 1px solid #142c43;
  color: #142c43;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 20px;
}
